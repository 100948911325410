/* eslint-disable max-len */
const laRubriquePosts = require('./json/laRubriquePosts/index');
const laRubriqueCategories = require('./json/laRubriqueCategories.json');
const landings = require('./json/landingsPartners.json');
const localitiesLandings = require('./json/landingsLocalities.json');
const localitiesLeatherGoodsLandings = require('./json/landingsLocalitiesLeatherGoods.json');
const localitiesShoesLandings = require('./json/landingsLocalitiesShoes.json');
const localitiesRepairLandings = require('./json/landingsLocalitiesRepair.json');
const landingsAds = require('./json/landingsAds.json');
const landingsAdvices = require('./json/landingsAdvices.json');
const landingsAdsPieces = require('./json/landingsAdsPieces.json');
const landingsAdsCurtains = require('./json/landingsAdsCurtains.json');
const {
  getSeo, getPath, getRepairPath, getLeatherGoodsPath, getLeatherGoodsSeo, getShoesPath, getShoesSeo, getRepairSeo,
} = require('./services/seoLocalitiesFormatter');
const { getPostSeo } = require('./services/seoPostsFormatter');
const routesHome = require('./config/routes/routes.home');
const routesMyAccount = require('./config/routes/routes.myAccount');
const routesOrder = require('./config/routes/routes.order');

Object.keys(routesOrder).forEach((route) => {
  routesOrder[route].isRouteNotIndexedBySearchEngine = true;
});

const routes = {
  ...routesHome,
  ...routesMyAccount,
  ...routesOrder,
};

laRubriquePosts.forEach((post) => {
  routes[`LaRubrique_${post.slug}`] = {
    url: post.path,
    seo: getPostSeo(post),
  };
});

laRubriqueCategories.forEach((category) => {
  routes[`LaRubriqueCategory_${category.slug}`] = {
    url: category.path,
    seo: category.seo,
  };

  (category.subCategories || []).forEach((subCategory) => {
    (subCategory.items || []).forEach((item) => {
      routes[`LaRubriqueCategory_${item.slug}`] = {
        url: item.path,
        seo: item.seo,
      };
    });
  });
});

landings.forEach((landing) => {
  routes[landing.slug] = {
    url: landing.path,
    seo: landing.seo,
  };
});

localitiesLandings.forEach((landing) => {
  routes[landing.slug] = {
    url: getPath(landing),
    seo: getSeo(landing),
  };
});

localitiesRepairLandings.forEach((landing) => {
  routes[landing.slug] = {
    url: getRepairPath(landing),
    seo: getRepairSeo(landing),
  };
});

localitiesLeatherGoodsLandings.forEach((landing) => {
  routes[landing.slug] = {
    url: getLeatherGoodsPath(landing),
    seo: getLeatherGoodsSeo(landing),
  };
});

localitiesShoesLandings.forEach((landing) => {
  routes[landing.slug] = {
    url: getShoesPath(landing),
    seo: getShoesSeo(landing),
  };
});

landingsAds.forEach((landing) => {
  routes[landing.slug] = { url: landing.url, isRouteNotIndexedBySearchEngine: true };
});
landingsAdvices.forEach((landing) => {
  routes[landing.slug] = { url: landing.url, isRouteNotIndexedBySearchEngine: true };
});
landingsAdsPieces.forEach((landing) => {
  routes[landing.slug] = { url: landing.url, isRouteNotIndexedBySearchEngine: true };
});
landingsAdsCurtains.forEach((landing) => {
  routes[landing.slug] = { url: landing.url, isRouteNotIndexedBySearchEngine: true };
});

/**
 * Return the route url for a page of category articles
 * Returned url looks like `/la-rubrique/:category/articles/:pageNumber`
 * @param {string} categorySlug the category slug
 * @param {number} pageNumber the page number
 * @returns {string} the url of the page
 */
function getCategoryArticlePageUrl(categorySlug, pageNumber) {
  const { url } = routes[`LaRubriqueCategory_${categorySlug}`] || {};
  return url && `${url.replace(/\/$/, '')}/articles/${pageNumber}/`;
}

module.exports = routes;
module.exports.getCategoryArticlePageUrl = getCategoryArticlePageUrl;
